import $ from "jquery";
import '../styles/css/couleurs.css';
import '../styles/css/mots.css';
import '../styles/css/confirm.css';
import {confirm_delete, confirm_dialog} from "../outils/OutilsConfirm";
import {toggleDiv, toggleDivOnClick} from "../outils/OutilsDivers";



$(document).ready(function () {

    // activation des hide/show sur les boutons du menu
   toggleDiv()

   // //activation du hide/show sur un bouton avec la classe js-arrow
   toggleDivOnClick()

   confirm_dialog()
   confirm_delete()


})




