
import '../../node_modules/jquery-confirm/dist/jquery-confirm.min.css';
import '../../node_modules/jquery-confirm/dist/jquery-confirm.min.js';
import '../styles/css/confirm.css';
import {managePicture} from "./OutilsPhotos";
import $ from "jquery";

export function confirm_form(offcanvas=''){
    //action form
    $(".confirm-form").on("click", function () {
        if (offcanvas!==''){ offcanvas.hide() }
        let entity=$(this)
        confirm_formulaire(entity)
    })
}


export function confirm_dialog(fonction=''){
    //action modal - ouvre un confirm modal avec du texte uniquement
    $(".confirm-dialog").on("click", function () {
        let entity=$(this);
        confirm_dialog_action(entity,fonction)
    })
}

export function confirm_delete(){

    //action suppression
    $(".confirm-delete").on("click", function (e) {
       let entity=$(this)
        confirm_delete_action(entity)
    })
}

export function confirm_formulaire(entity='',url=''){
    let adresse='';
    if (entity===''){
        adresse=url
    }else{
        adresse=$(entity).attr('data-url')
    }

    var jc= $.confirm({
        title:false,
        content: function () {
            var self = this;
            return $.ajax({
                url: adresse,
                dataType: 'json',
                method: 'POST'
            }).done(function (response) {
                self.setContent( response['content']);

            }).fail(function(){
                self.setContent('Il y a eu un problème');
            });
        },
        columnClass: 'large',

        buttons: {
            cancel: {
                text: 'Annuler',
                action: function () {
                }
            },
            formSubmit: {
                text: 'Sauvegarder',
                btnClass: 'btn-primary text-white',
                action: function () {

                    // Ajouter le spinner après le bouton "Sauvegarder"
                    $(".jconfirm-buttons").append('<div class="loading-spinner text-center text-secondary fw-semibold"><i class="fa fa-spinner fa-spin me-1"></i>en cours...</div>');


                    let form = $("form");
                    $.ajax({
                        url: form.attr('action'),
                        data: form.serialize(),
                        method: 'POST'
                    }).done(function (response) {
                        if (response['code'] === 201) {
                            jc.close()
                            window.location.assign(response['url'])
                        } else if (response['code'] === 202) {
                            jc.close()
                        } else if (response['code'] === 300) {
                            // cas d'un tag validé après submit
                            jc.close()
                            if(response['cat_change']===1){
                                entity.parent().remove()
                            }

                        } else if (response['code'] === 200) {
                            $(".jconfirm-content").html(response['content']);
                        }
                    })
                    return false;

                }

            },

        },
        onContentReady: function () {
            confirm_delete()
            managePicture()
            keyPressReturn(jc)



        },




    });

}

function keyPressReturn(jc){
    $(document).on('keypress', function (e) {
        if(e.which===13){
            let form=$('form');
            e.preventDefault();
            $.ajax({
                url: form.attr('action'),
                data: form.serialize(),
                method: 'POST'
            }).done(function (response) {
                if (response['code'] === 201) {
                    jc.close()
                    window.location.assign(response['url'])
                } else if (response['code'] === 202) {
                    jc.close()
                } else if (response['code'] === 200) {
                    $(".jconfirm-content").html(response['content']);
                }
            })
        }

    })
}





function confirm_delete_action(entity){
    let libelle=entity.attr("data-libelle");
    let action=entity.attr("data-action");
    let token=entity.attr("data-token");
    let retour=$("#page").attr("data-retour")
    let jc=$.confirm({
        theme:'Modern',
        icon:'fa-regular fa-shield-exclamation text-danger',
        title: 'Attention !',
        content: '' +
            '<form id="form-confirm" method="post" action="'+action+'" class="formName">' +
            '<div class="form-group">' +
            '<label>'+libelle+'</label>' +
            '<input type="hidden" name="_token" value="'+token+'"  />' +
            '</div>' +
            '</form>',
        buttons: {
            cancel: {
                text: 'Annuler',
                action: function () {
                }
            },
            formSubmit: {

                text: 'OUI',
                btnClass: 'btn-success text-white',
                action: function () {

                    let form=$('#form-confirm');
                    $.ajax({
                        url: form.attr('action'),
                        data: form.serialize(),
                        method: 'POST'
                    }).done(function (response) {

                        if (response['code'] === 200) {
                            if(typeof retour !== 'undefined'){
                                window.location.assign(retour)
                            }else{
                                window.location.assign(response['url'])
                            }


                        }
                        if (response['code'] === 205) {

                            alert_message(response['message'],response['titre'])
                        }
                    })
                }
            },

        },
        onContentReady: function (e) {
            // // bind to events
            var jc = this;
            $(document).on('keypress', function (e) {
                if (e.which === 13) {
                    jc.close()
                }
            });
        }
    })


}

// Cette fonction ouvre un modal. L'information contenue dans le modal est obtenue via un ajax
// sur l'url obtenue dans l'entité qui possède la classe  "confirm-dialog" et un attribut "data-url"
// la variable fonction donne le nom des fonctions qui doivent être executées à l'ouverture
function confirm_dialog_action(entity='',fonctionOnOpen='',fonctionOnClose='') {

    let url=entity.attr("data-url");
    $.confirm({
        title: false,
        content: function () {
            var self = this;
            return $.ajax({
                url: url,
                dataType: 'json',
                method: 'POST'
            }).done(function (response) {
                self.setContent( response['content']);

            }).fail(function(){
                self.setContent('Il y a eu un problème');
            });
        },
        columnClass: 'large',
        // backgroundDismiss: true,
        buttons: {
            cancel: {
                text: 'Fermer',
                btnClass: 'btn-primary text-white',
                action: function () {
                }
            },
            specialKey: {
                isHidden: true,
                keys: ['enter'],
                action: function () {
                }
            }
        },
        onContentReady: function () {
            var jc=this
            if(fonctionOnOpen==='manageModalRecords'){
                manageModalRecords()
            }else{
                $(document).on('click', function (e) {
                    jc.close()
                })
            }



        }

    })


}

function alert_message(message,titre=''){

    $.alert({
        title: titre,
        icon:'fa-regular fa-exclamation',
        type: 'red',
        theme: 'Modern',
        backgroundDismiss:true,
        content:message,
        onContentReady: function (e) {
            // // bind to events
            var jc = this;
            $(document).on('keypress', function (e) {
                if (e.which === 13) {
                    jc.close()
                }
            }).on('click', function (e) {
                jc.close()
            });
        }
    })


}




// export function form_submit(jc){
//     let form = $("form");
//     $.ajax({
//         url: form.attr('action'),
//         data: form.serialize(),
//         method: 'POST'
//     }).done(function (response) {
//
//         if (response['code'] === 201) {
//             // console.log('coucou thierry')
//             jc.close()
//             window.location.assign(response['url'])
//         }else if(response['code'] === 202){
//             jc.close()
//         }else if (response['code'] === 200) {
//             $(".jconfirm-content").html(response['content']);
//         }
//     })
//
// }
